@import "~bootswatch/dist/slate/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/slate/bootswatch";

@font-face {
  font-family: "Droid Sans Mono";
  src: url("./fonts/DroidSansMono.ttf");
}

@font-face {
  font-family: "Font Awesome Regular";
  src: url("./fonts/fa-regular-400.ttf") format("truetype"),
    url("./fonts/fa-regular-400.woff2") format("woff2");
}

@font-face {
  font-family: "Font Awesome Solid";
  src: url("./fonts/fa-solid-900.ttf") format("truetype"),
    url("./fonts/fa-solid-900.woff2") format("woff2");
}

.App {
  text-align: center;
}

.main-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #E3FFF3;
}

.test-leve-info-banner {
    position: absolute;
    top: 50px;
    font-size: 24px;
    color: black;
}

.instruction-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 500px;
  height: 500px;
  border: 3px solid black;
  border-radius: 15px;
  z-index: 10;

  .instruction-modal-title {
    font-size: 26px;
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
    margin-left: 15px;
    text-align: left;
    color: black;
  }

  .instruction-modal-body {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .instruction-modal-text {
    width: 90%;
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: left;
    color: black;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;

    // b {
    //   line-height: 26px;
    //   color: white;
    //   background-color: black;
    //   padding: 2px 5px;
    //   font-weight: 600;
    // }

    a {
      color: blue;
      text-decoration: underline;
    }

    ul {
      margin-top: 1em;
    }

    .please-read {
      background-color: red;
      color: white;
      padding: 10px;
    }
  }

  .instruction-modal-character-key {
    margin-bottom: 1rem;
  }

  .instruction-modal-button-row {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-top: 3px solid black;

    .instruction-modal-button {
      width: 140px;
      height: 40px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    .instruction-modal-button.next {
      border: 3px solid #00916E;
      color: #00916E;
      background: #d6fff1;
    }

    .instruction-modal-button.back {
      border: none;
      background: #C4C4C4;
    }

    .instruction-modal-button.start {
      width: 200px;
      background: #00916E;
      color: white;
      border: none;
    }
  }
}

.agent-popup {
  min-width: 300px;
  z-index: 30;
  background: white;
  padding: 4px 15px;
  padding-top: 25px;
  border-radius: 10px;
  border: 2px solid black;

  .agent-popup-title {
    font-size: 26px;
    font-weight: bold;
    color: black;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    color: #404040;
  }

  .agent-popup-agent {
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 3px;
    padding: 30px;
    border-radius: 20px;

    .agent-popup-name {
      font-size: 40px;
      line-height: 40px;
      margin-top: 5px;
      font-weight: bold;
      color: #5a5a5a;
    }
  }

  .agent-popup-button-row {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    margin-top: 15px;

    .agent-popup-button {
      width: 120px;
      height: 35px;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 600;
      background: #00916E;
      color: white;
      border: none;
    }
  }
}

.level-items-popup {
  min-width: 300px;
  z-index: 30;
  background: white;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid black;

  .level-items-popup-header {
    font-size: 26px;
    font-weight: bold;
    color: black;
  }

  .level-items-popup-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .level-items-popup-item {
      height: 100px;
      width: 100px;
      margin: 5px;
    }
  }

  .level-items-popup-button-row {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    margin-top: 15px;

    .level-items-popup-button {
      width: 120px;
      height: 35px;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 600;
      background: #00916E;
      color: white;
      border: none;
    }
  }
}

.delay-button {
  display: flex;
  align-items: center;
  justify-content: center;

  .delay-button-icon {
    margin-left: 10px;
  }

  .delay-button-countdown {
    height: 25px;
    margin-left: 7px;
    margin-bottom: 7px;
  }
}

.quiz-modal {
  width: 400px;
  z-index: 40;
  background: white;
  border-radius: 15px;
  border: 3px solid black;
  text-align: left;

  .quiz-modal-title {
    font-size: 26px;
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
    margin-left: 15px;
    margin-bottom: 20px;
    text-align: left;
    color: black;
  }

  .quiz-modal-body {
    display: flex;
    flex-direction: column;
    margin: 0 50px 20px 15px;

    .quiz-modal-text {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      color: black;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }

    .quiz-modal-option-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      .quiz-modal-option {
        padding: 10px;
        border-radius: 15px;
        margin-right: 10px;
        background-color: #eeeeee;
        cursor: pointer;

        .quiz-modal-equal {
          height: 50px;
          width: 50px;
          font-size: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #5A5A5A
        }
      }

      .quiz-modal-option.selected {
        background-color: #c6c6c6;
        // color: white;
      }

      .quiz-modal-option.correct {
        background-color: #00916E;
      }

      .quiz-modal-option.incorrect {
        background-color: #FF0000;
      }
    }
  }

  .quiz-modal-button-row {
    border-top: 3px solid black;
    padding: 15px;
    display: flex;
    justify-content: flex-end;

    .quiz-modal-button {
      height: 40px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 600;
      background: #00916E;
      color: white;
      border: none;
    }
  }
}

.avatar {
    width: 60px;
    height: 60px;
    padding: 10px;

    .avatar-eyes {
        width: 40px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;

        .avatar-eye {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            .avatar-pupil {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: black;
            }

            .avatar-pupil.large {
                width: 20px;
                height: 20px;
            }
        }

        .avatar-eye.large {
            width: 42.5px;
            height: 42.5px;
        }
    }

    .avatar-eyes.large {
        width: 100px;
        height: 40px;
        margin-top: 10px;
    }

    .avatar-mouth {
        width: 40px;
        height: 6px;
        background-color: white;
        margin-top: 3px;
        border-radius: 20px;
    }

    .avatar-mouth.large {
        width: 100px;
        height: 15px;
        margin-top: 15px;
    }
}

.avatar.large {
    width: 150px;
    height: 150px;
    padding: 25px;
}

.game-container {
  position: absolute;
  display: flex;
  width: 1040px;
  height: 655px;
  border-radius: 20px;
  z-index: 5;

  .avatar-container {
    width: 70px;
    height: 90px;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name-badge {
        width: 75px;
        height: 30px;
        border-radius: 5px;
        font-size: 20px;
        line-height: 25px;
        background-color: black;
        color: white;
    }
  }

  .emoji-bubble {
    position: absolute;
    z-index: 200;
    color: #ffe8c9;
    font-size: 70px;

    .emoji-bubble-emoji {
      position: absolute;
      top: 20px;
      left: 14px;
      font-size: 40px;
    }
  }

  .reward-history {
    width: 225px;
    height: 100%;
    margin-left: 15px;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 20px;
    border: 3px solid black;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    // padding: 5px;

    .reward-history-title {
      padding: 2px 10px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: black;
      border-bottom: 3px solid black;
    }

    .reward-history-list {
      padding: 2px;
      overflow-y: scroll;

      .reward-history-item {
        display: flex;
        height: 42px;
        margin: 5px 5px 7px;
        border-radius: 5px;
        background-color: #eee;
        color: black;
        font-size: 18px;
        overflow: hidden;

        .reward-history-item-agent {
          display: flex;
          align-items: center;
          padding: 5px 8px;
          color: white;
          font-size: 16px;
          font-weight: bold;
        }

        .reward-history-item-reward {
          padding: 8px;
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

.info-bar {
  position: relative;
  z-index: 10;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
  padding: 0 12px;
  border: 3px solid black;
  border-radius: 20px 20px 0 0;
}

.info-bar-playing {
  font-size: 20px;
  font-weight: bold;
}

.info-bar-stats {
  display: flex;
  flex-direction: row;
}

.info-bar-stats-item {
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin-left: 10px;
  height: fit-content;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: rgb(255, 215, 215);
}

.info-bar-stats-item-key {
  margin-right: 5px;
}

.score-popup {
  position: absolute;
  left: 240px;
  top: 250px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  width: 280px;
  height: 80px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  .score-popup-score {
    display: flex;
    align-items: center;

    img {
      margin-right: 7px;
    }
  }

  .score-popup-score.high {
    color: black;
    font-weight: bold;
    font-size: 26px;
  }

  .score-popup-score.medium {
    color: #7f7f7f;
    font-weight: normal;
    font-size: 22px;
  }

  button {
    width: 70px;
    height: 30px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: black;
    color: white;
    border: none;
  }
}

// .score-popup.high {
//   background: #00C14D;
// }

// .score-popup.medium {
//  background: #FF9900;
// }

// .score-popup.low {
//   background: #FF0000;
// }

.button-panel {
  text-align: left;
  min-height: 15px;
  max-height: 40px;
}

.header-logo {
  text-align: left;
  padding: 10px;
}

.header-navlinks {
  text-align: right;
  padding: 10px;

  .navlink {
    padding-left: 20px;
  }
}

.header-project {
  font-size: 24;
  padding: 10px;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
}

.tutorial-video {
  text-align: center;
  margin: 20px;
}

.message-text {
  white-space: pre-wrap;
  text-align: left;
  font-family: monospace;
}

.loading-gif {
  margin: auto;
}

.loading-logo {
  margin: auto;
}

.item-values-container {
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;

  .item-values-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: -7px;

    span {
      margin-top: -8px;
    }
  }
}

.experiment-complete-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 525px;
  height: 625px;
  border: 3px solid black;
  border-radius: 15px;
  z-index: 10;

  .experiment-complete-modal-title {
    font-size: 26px;
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
    margin-left: 15px;
    text-align: left;
    color: black;
  }

  .experiment-complete-modal-body {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .experiment-complete-modal-text {
    width: 90%;
    margin-left: 20px;
    margin-bottom: 1rem;
    text-align: left;
    color: black;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  textarea {
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    color: black;
    font-size: 16px;
    resize: none;
  }

  .experiment-complete-modal-button-row {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    border-top: 3px solid black;

    .experiment-complete-modal-button {
      height: 40px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 600;
      width: 200px;
      background: #00916E;
      color: white;
      border: none;
    }
  }
}
